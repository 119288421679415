import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import VueGtag from "vue-gtag";

const app = createApp(App);

// Load router
app.use(router);
app.use(store);

app.use(VueGtag, {
	config: { id: "AW-16722545617" }, // Remplacez par votre ID Google Analytics 4
}, router);

app.mount("#app");
